<template>
	<div>
		<backTo :link="'/admin/quizes'"><template #text>Back to Quizes</template></backTo>
		<span class="ml-10 mr-10 text-brand clickable" @click="goToQuizSettings"><b><u>Quiz Settings</u></b></span>
		<v-btn
			:isLoading="isLoading"
			class="admin-primary-button primary-contrast-background"
			@click="gotoAssignToCourse"
			>Assign to Course</v-btn
			>
	</div>
	<!-- <v-container class="quizes-editor-header secondary-background">
		<v-row class="centered">
			<v-col cols="3">
				<v-row>
					<v-col cols="12 px-0">
						<div
							v-if="!isQuizNameEdited"
							class="d-flex flex-row align-center"
						>
							<h4>{{ quizTitle }}</h4>

							<v-icon
								class="ml-4"
								@click="setIsQuizNameEdited(true)"
								>mdi-pencil-outline</v-icon
							>
						</div>
						<div v-else class="d-flex flex-row align-center">
							<input-text-field v-model="newQuizName">
								<template #label>Quiz name</template>
							</input-text-field>
							<v-icon class="ml-4" @click="saveQuizName"
								>mdi-check-outline</v-icon
							>
							<v-icon class="ml-4" @click="cancelEditing"
								>mdi-close</v-icon
							>
						</div>
					</v-col>
					<v-col cols="6 py-0 px-0">
						<small
							>Saved {{ saveTime }} day<small v-if="saveTime > 1"
								>s</small
							>
							ago</small
						></v-col
					>
					<v-col cols="6 py-0 px-0">
						<small>Course Status {{ status }}</small>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="3">
				<backTo :link="'/admin/quizes'"
					><template #text>Back to quizes</template></backTo
				>
			</v-col>

			<v-col cols="6" class="text-right">
				<span class="mr-10 clickable" @click="goToQuizSettings"
					>Quiz Settings</span
				>
				<v-btn
					:isLoading="isLoading"
					class="admin-primary-button primary-contrast-background"
					@click="gotoAssignToCourse"
					>Assign to Course</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>
<script>
import InputTextField from "@/components/InputTextField.vue";
import BackTo from "@/components/BackTo.vue";
import { put } from "@/util/requests/put";
import { mapGetters } from "vuex";

export default {
	name: "CourseEditorHeader",
	components: { InputTextField, BackTo },
	props: {
		quiz: {
			type: Object,
			require: false
		},
		quizTitle: {
			type: String,
			require: true
		}
	},
	computed: {
		...mapGetters(["isLoading", "organization"]),
		saveTime() {
			return Math.ceil(
				Number(new Date() - new Date(this.quiz.updated_at)) /
					1000 /
					60 /
					60 /
					24
			);
		},
		status() {
			return this.quiz.published_at === null ? "Draft" : "Published";
		},
		quizId() {
			return this.quiz?.id;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isQuizNameEdited: false,
			newQuizName: ""
		};
	},
	methods: {
		setIsQuizNameEdited(val) {
			this.isQuizNameEdited = val;
		},
		async cancelEditing() {
			this.setIsQuizNameEdited(false);
			this.newQuizName = "";
		},
		async saveQuizName() {
			await this.$store.dispatch("setIsLoading", true);
			await put("/quiz", this.quizId, {
				name: this.newQuizName,
				organization_id: this.organization.id,
				start_button_text: this.quiz.start_button_text,
				started_heading: this.quiz.started_heading,
				started_html: this.quiz.started_html,
				completed_heading: this.quiz.completed_heading,
				completed_html: this.quiz.completed_html,
				sort: this.quiz.sort,
				custom: this.quiz.custom,
				custom_course_id: this.quiz.custom_course_id,
				category_id: this.quiz.category_id,
				type: this.quiz.type
			});
			await this.$emit("getQuiz");
			await this.cancelEditing();
			await this.$store.dispatch("setIsLoading", false);
		},
		goToQuizSettings() {
			this.$router.push({
				name: "QuizSettings",
				params: { id: this.$route.params.id }
			});
		},
		gotoAssignToCourse() {
			this.$router.push({
				name: "QuizAssign",
				params: { id: this.$route.params.id }
			});
		}
	}
};
</script>

<style></style>
